function switchCurrency(value) {
    // console.log(`Switching Currency: ${value}`);
    setSavedCurrency(value);
}

function calculatePrices(){
    var currency = getSavedCurrency();
    switch (currency) {
        case "AED": {
            return {
                individualPrice: "250 AED",
                scriboPrice: "200 AED",
                pairPrice: "350 AED"
            }
        }
        case "RUB": {
            return {
                individualPrice: "6500 руб.",
                scriboPrice: "6000 руб.",
                pairPrice: "10000 руб."
            }
        }
        case "USD": {
            return {
                individualPrice: "65 USD",
                scriboPrice: "60 USD",
                pairPrice: "95 USD"
            }
        }
        case "EUR": {
            return {
                individualPrice: "60 EUR",
                scriboPrice: "55 EUR",
                pairPrice: "90 EUR"
            }
        }
        default: {
            return {
                individualPrice: "250 AED",
                scriboPrice: "200 AED",
                pairPrice: "350 AED"
            }
        }
    }
}

function getSavedCurrency(){
if (sessionStorage !== undefined) {
    var currency = sessionStorage.getItem('currency');
    // console.log(`Saved Currency: ${currency}`);
    return currency
}
return "AED"
}

function setSavedCurrency(value){
if (sessionStorage !== undefined) {
    sessionStorage.setItem('currency', value);
}
// console.log(`Saved Currency: ${value}`);
}

export { switchCurrency, getSavedCurrency, calculatePrices}
  